const ArticleHeading: FH<{ title: string }> = ({ title }, ...children) => div(
  {
    css: {
      position: 'relative',
      marginTop: '8rem',
      paddingBottom: '1rem',
      backgroundImage: [
        `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"><rect x="0" y="0" width="100%" height="100%" fill="white" fill-opacity="0.5"></rect></svg>')`,
        `url('https://yokoiwa-s3.imgix.net/Sunyou/AdobeStock_090361790.jpeg?w=640&q=50')`,
      ].join(', '),
      backgroundSize: 'cover',
      backgroundPosition: 'center bottom',
    },
  },
  div(
    {
      css: {
        position: 'relative',
        margin: '0 auto',
        maxWidth: '50rem',
      },
    },
    div( // title decoration
      {
        css: {
          position: 'absolute',
          left: '1rem',
          transform: 'translateY(-50%)',
          width: '8rem', height: '8rem',
          backgroundImage: 'url(https://yokoiwa-s3.imgix.net/Sunyou/polygonal-pattern.svg)',
          backgroundSize: '250px',
          backgroundColor: '#426CA2',
          '::before': {
            content: '""', display: 'block',
            position: 'absolute',
            left: '.5rem', top: '.5rem', right: '.5rem', bottom: '.5rem',
            border: '4px solid #FFF',
          },
        },
      },
    ),
  ),
  div( // title content
    {
      css: {
        position: 'relative',
        transform: 'translateY(-50%)',
        backgroundImage: 'url(https://yokoiwa-s3.imgix.net/Sunyou/polygonal-pattern.svg)',
        backgroundSize: '200px',
        backgroundColor: '#30387A',
        color: '#FFF',
        fontSize: '1.5em',
      },
    },
    h1(
      {
        css: {
          margin: '0 auto',
          maxWidth: '50rem',
          padding: '1rem',
        },
      },
      span({}, title),
    ),
  ),
  div( // section content area
    {
      css: {
        margin: '1rem auto',
        maxWidth: '42rem',
        padding: '1rem',
        fontWeight: 700,
        lineHeight: 2,
      },
    },
    (children.length > 0) && div({ css: { backgroundColor: 'rgba(255,255,255,.9)', padding: '2rem 3rem 1rem' } },
      ...children,
    ),
  ),
)

export default ArticleHeading
