import { keyframes } from '@emotion/core'

const animFadeIn = keyframes({
  from: { opacity: 0, transform: 'translateX(-100%)' },
  to: { opacity: 1, transform: 'translateX(0)' },
})

const HeroAreaCopyLine: FH = () => div(
  {
    css: {
      // outer
      position: 'absolute',
      width: '100%', height: '12.5vh',
      transform: 'translate(0, 100%)',
      fontSize: '2.5vh',
      '@media(min-width: 50vh)': {
        height: '25vw',
        fontSize: '5vw',
      },
      '@media(min-width: 66.666666vh)': {
        height: '16.666666vh',
        fontSize: '3.333333vh',
      },
      '@media(min-width: 100vh)': {
        height: '16.666666vw',
        transform: 'translate(0, 0)',
        paddingLeft: 'calc(50% + 10px + 1em)',
        justifyContent: 'flex-start',
        fontSize: '3.333333vw',
      },
      '@media(min-width: 150vh)': {
        height: '25vh',
        fontSize: '5vh',
      },
      // self
      backgroundImage: 'url(https://yokoiwa-s3.imgix.net/Sunyou/polygonal-pattern.svg)',
      backgroundSize: '200px',
      backgroundColor: '#30387A',
      boxShadow: '0 0 2rem -1rem #FFF',
      // inner
      display: 'flex',
      justifyContent: 'center', alignItems: 'center',
    },
  },
  p(
    {
      css: {
        animationName: animFadeIn.toString(),
        animationDelay: '500ms',
        animationDuration: '1000ms',
        animationFillMode: 'both',
        color: '#FFF',
        fontSize: '.9em',
        marginBottom: 0,
        paddingTop: '10px',
        paddingBottom: '10px',
        // '@media(min-width: 100vh)': {
        //   paddingLeft: '15px',
        // },
        // borderLeft: '2px solid #FFF',
      },
    },
    '1973年創業。',br(),'びんのリサイクルなら、三裕。',
  ),
)

export default HeroAreaCopyLine
