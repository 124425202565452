import { keyframes } from '@emotion/core'

const animFadeIn = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 },
})

const ImgGridItem: FH<{area: string, src: string}> = ({ area, src }) => div({
  css: [{ gridArea: area }],
},[
  img({
    src,
    alt: '',
    css: { display: 'block', width: '100%', height: '100%' },
  }),
])

const HeroAreaBackground: FH = () => div(
  {
    css: {
      animationName: animFadeIn.toString(),
      animationDelay: '2000ms',
      animationDuration: '2000ms',
      animationFillMode: 'both',
      // outer
      position: 'absolute',
      width: '50vh', height: '100vh',
      // innner
      display: 'grid',
      gridTemplateColumns: '50% 1fr',
      // gridTemplateColumns: '50% 50%',
      gridTemplateRows: '1fr 1fr 1fr 1fr',
      gridTemplateAreas: '"_01 _02" "_07 _05" "_04 _03" "_06 _08"',
      '>div:last-child': {
        display: 'none',
      },
      '@media(min-width: 50vh)': {
        width: '100vw', height: '200vw',
      },
      '@media(min-width: 66.666666vh)': {
        width: '100vh', height: '100vh',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridTemplateRows: '1fr 1fr 1fr',
        gridTemplateAreas: '"_01 _02 _04" "_07 _05 _03" "_08 _06 _09"',
        '>div:last-child': {
          display: 'block',
        },
      },
      '@media(min-width: 100vh)': {
        width: '100vw', height: '100vw',
      },
      '@media(min-width: 150vh)': {
        width: '200vh', height: '100vh',
        gridTemplateColumns: '25% 25% 25% 25%',
        gridTemplateRows: '1fr 1fr',
        gridTemplateAreas: '"_01 _02 _04 _03" "_07 _05 _06 _08"',
        '>div:last-child': {
          display: 'none',
        },
      },
      '@media(min-width: 200vh)': {
        width: '100vw', height: '50vw',
      },
    },
  },
  ImgGridItem({ area: '_01', src: 'https://yokoiwa-s3.imgix.net/Sunyou/AdobeStock_271628355.jpeg?q=0&fm=pjpg&h=800&w=800&fit=crop' }),
  ImgGridItem({ area: '_02', src: 'https://yokoiwa-s3.imgix.net/Sunyou/AdobeStock_256164454.jpeg?q=0&fm=pjpg&h=800&w=800&fit=crop' }),
  ImgGridItem({ area: '_03', src: 'https://yokoiwa-s3.imgix.net/Sunyou/AdobeStock_329067525.jpeg?q=0&fm=pjpg&h=800&w=800&fit=crop' }),
  ImgGridItem({ area: '_04', src: 'https://yokoiwa-s3.imgix.net/Sunyou/AdobeStock_207774289.jpeg?q=0&fm=pjpg&h=800&w=800&fit=crop' }),
  ImgGridItem({ area: '_05', src: 'https://yokoiwa-s3.imgix.net/Sunyou/AdobeStock_304941428.jpeg?q=0&fm=pjpg&h=800&w=800&fit=crop' }),
  ImgGridItem({ area: '_06', src: 'https://yokoiwa-s3.imgix.net/Sunyou/AdobeStock_220632879.jpeg?q=0&fm=pjpg&h=800&w=800&fit=crop' }),
  ImgGridItem({ area: '_07', src: 'https://yokoiwa-s3.imgix.net/Sunyou/AdobeStock_261851914.jpeg?q=0&fm=pjpg&h=800&w=800&fit=crop' }),
  ImgGridItem({ area: '_08', src: 'https://yokoiwa-s3.imgix.net/Sunyou/AdobeStock_211995995.jpeg?q=0&fm=pjpg&h=800&w=800&fit=crop' }),
  ImgGridItem({ area: '_09', src: 'https://yokoiwa-s3.imgix.net/Sunyou/AdobeStock_302184583.jpeg?q=0&fm=pjpg&h=800&w=800&fit=crop' }),
)

export default HeroAreaBackground
