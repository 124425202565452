const SubSection: FH<{ title: string }> = ({ title }, ...children) => section(
  {},
  div(
    {
      css: {
        position: 'relative',
        marginBottom: '1rem',
        backgroundColor: '#426CA2',
        backgroundImage: 'url(https://yokoiwa-s3.imgix.net/Sunyou/polygonal-pattern.svg)',
        backgroundSize: '200px',
        color: '#FFF',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        paddingLeft: '2rem',
        fontSize: '1.25em',
      },
    },
    h1( // title content
      {
        css: {
          margin: '0 auto',
          maxWidth: '45rem',
          fontWeight: 400,
          verticalAlign: 'baseline',
          '::before': {
            content: '""',
            marginRight: '.75em',
            display: 'inline-block',
            width: '1em', height: '1em',
            border: '3px solid #FFF',
            transform: 'translateY(10%)',
          },
        },
      },
      span({}, title),
    ),
  ),
  div(
    {
      css: {
        margin: '1rem auto 2rem',
        maxWidth: '40rem',
        backgroundColor: '#FFF',
        padding: '1.5rem 1rem .5rem',
        '>figure': {
          margin: '0 -.5rem 1rem',
          padding: '.5rem',
          backgroundColor: '#EEF',
          borderRadius: '.25rem',
          '>img': {
            display: 'block',
            border: '2px solid #FFF',
            // borderRadius: '.25rem',
          },
          '>figcaption': {
            margin: '.5em .25em 0',
            fontSize: '.8em',
            '::before': {
              content: '"△"',
              display: 'inline-block',
              marginRight: '.25em',
            },
          },
        },
      },
    },
    ...children,
  ),
)

export default SubSection
