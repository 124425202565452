export default function smoothScroll(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
  e.preventDefault()
  const linkElement = e.currentTarget
  if (!(linkElement instanceof Element)) return
  const targetId = linkElement.getAttribute('href')?.replace('#','')
  if (!targetId) return
  const targetElement = document.getElementById(targetId)
  if (!targetElement) return
  const targetCoordY = window.scrollY + targetElement.getBoundingClientRect().top - 160
  window.scrollTo({ top: targetCoordY, left: 0, behavior: 'smooth' })
}
