import { useState } from 'react'
import smoothScroll from '../utils/smoothScroll'

const HeaderNav: FH = () => {
  const [isOpened, toggleIsOpened] = useState(false)
  return div(
    {
      onClick: () => toggleIsOpened(!isOpened),
      css: {
        position: 'fixed',
        zIndex: 100,
        left: 0, bottom: 0,
        width: '100%', height: '100vh',
        overflow: 'hidden',
        '@media(min-width: 100vh)': {
          position: 'sticky',
          bottom: 'auto', top: 0,
          marginBottom: 'calc(-100vh + 4rem)',
        },
        pointerEvents: 'none',
      },
    },
    div(
      { css: {
        position: 'absolute',
        left: 0, bottom: 0,
        '@media(min-width: 100vh)': {
          bottom: 'auto', top: 0,
        },
        width: '100%' ,height: '4rem',
        backgroundColor: '#FFF',
        display: 'flex',
        justifyContent: 'center', alignItems: 'flex-end',
        boxShadow: '0 0 1rem -.5rem #000',
        pointerEvents: 'auto',
      } },
      img({ css: { position: 'absolute', zIndex: 2, marginTop: '-.5rem', height: '60%' }, src: 'https://yokoiwa-s3.imgix.net/Sunyou/sunyou-logo.svg', alt: '「さんゆう」ロゴ' }),
      ul(
        {
          className: (isOpened ? 'opened' : ''),
          css: {
            position: 'absolute',
            left: 0, bottom: 0,
            width: '100%', height: '100vh',
            transform: 'translateY(100%)',
            '@media(min-width: 100vh)': {
              transform: 'translateY(0)',
            },
            transition: 'transform 200ms',
            '&.opened': {
              transform: 'translateY(0)',
              '@media(min-width: 100vh)': {
                transform: 'translateY(100%)',
              },
            },
            backgroundColor: 'rgba(255,255,255,.95)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center', alignItems: 'center',
            '@media(min-width:1000px)': {
              transform: 'translateY(0) !important',
              height: '100%',
              alignItems: 'flex-end',
              flexDirection: 'row',
              '>li:nth-of-type(2)': { marginRight: '12rem' },
            },
            margin: 0,
            listStyle: 'none',
            padding: 0,
            color: '#4D71A7',
            '>li': {
              width: '12em', height: '3rem',
              textAlign: 'center',
              '>a': {
                width: '100%', height: '100%',
                display: 'flex',
                justifyContent: 'center', alignItems: 'center',
                color: 'inherit',
              },
            },
          },
        },
        li({ key: 1 }, a({ onClick: smoothScroll, href: '#trust' }, '安心と信頼')),
        li({ key: 2 }, a({ onClick: smoothScroll, href: '#recycling' }, 'リサイクル')),
        li({ key: 3 }, a({ onClick: smoothScroll, href: '#facilities' }, '施設・設備')),
        li({ key: 4 }, a({ onClick: smoothScroll, href: '#company' }, '会社情報')),
      ),
    ),
  )
}

export default HeaderNav
