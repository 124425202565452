import {
  ReactHTML, HTMLFactory,
} from 'react'
import { jsx } from '@emotion/core'

const TAG_NAMES: Array<keyof ReactHTML> = ['a', 'abbr', 'address', 'area', 'article', 'aside', 'audio', 'b', 'base', 'bdi', 'bdo', 'big', 'blockquote', 'body', 'br', 'button', 'canvas', 'caption', 'cite', 'code', 'col', 'colgroup', 'data', 'datalist', 'dd', 'del', 'details', 'dfn', 'dialog', 'div', 'dl', 'dt', 'em', 'embed', 'fieldset', 'figcaption', 'figure', 'footer', 'form', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'head', 'header', 'hgroup', 'hr', 'html', 'i', 'iframe', 'img', 'input', 'ins', 'kbd', 'keygen', 'label', 'legend', 'li', 'link', 'main', 'map', 'mark', 'menu', 'menuitem', 'meta', 'meter', 'nav', 'noscript', 'object', 'ol', 'optgroup', 'option', 'output', 'p', 'param', 'picture', 'pre', 'progress', 'q', 'rp', 'rt', 'ruby', 's', 'samp', 'slot', 'script', 'section', 'select', 'small', 'source', 'span', 'strong', 'style', 'sub', 'summary', 'sup', 'table', 'template', 'tbody', 'td', 'textarea', 'tfoot', 'th', 'thead', 'time', 'title', 'tr', 'track', 'u', 'ul', 'video', 'wbr', 'webview']

// @ts-ignore
const h = Object.fromEntries(
  TAG_NAMES.map(
    (tag) => [
      tag,
      // @ts-ignore
      (props = {}, ...children) => jsx(tag, props, ...children),
    ],
  ),
)

export const { a, abbr, address, area, article, aside, audio, b, base, bdi, bdo, big, blockquote, body, br, button, canvas, caption, cite, code, col, colgroup, data, datalist, dd, del, details, dfn, dialog, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, head, header, hgroup, hr, html, i, iframe, img, input, ins, kbd, keygen, label, legend, li, link, main, map, mark, menu, menuitem, meta, meter, nav, noscript, object, ol, optgroup, option, output, p, param, picture, pre, progress, q, rp, rt, ruby, s, samp, slot, script, section, select, small, source, span, strong, style, sub, summary, sup, table, template, tbody, td, textarea, tfoot, th, thead, time, title, tr, track, u, ul, video, wbr, webview } = h

declare global{
  interface FH<P = {}> {
    (prop: P, ...children: Array<React.ReactNode>): React.ReactElement<any, any> | null
  }
}

declare global {
  const a: HTMLFactory<HTMLAnchorElement>
  const abbr: HTMLFactory<HTMLElement>
  const address: HTMLFactory<HTMLElement>
  const area: HTMLFactory<HTMLAreaElement>
  const article: HTMLFactory<HTMLElement>
  const aside: HTMLFactory<HTMLElement>
  const audio: HTMLFactory<HTMLAudioElement>
  const b: HTMLFactory<HTMLElement>
  const base: HTMLFactory<HTMLBaseElement>
  const bdi: HTMLFactory<HTMLElement>
  const bdo: HTMLFactory<HTMLElement>
  const big: HTMLFactory<HTMLElement>
  const blockquote: HTMLFactory<HTMLElement>
  const body: HTMLFactory<HTMLBodyElement>
  const br: HTMLFactory<HTMLBRElement>
  const button: HTMLFactory<HTMLButtonElement>
  const canvas: HTMLFactory<HTMLCanvasElement>
  const caption: HTMLFactory<HTMLElement>
  const cite: HTMLFactory<HTMLElement>
  const code: HTMLFactory<HTMLElement>
  const col: HTMLFactory<HTMLTableColElement>
  const colgroup: HTMLFactory<HTMLTableColElement>
  const data: HTMLFactory<HTMLDataElement>
  const datalist: HTMLFactory<HTMLDataListElement>
  const dd: HTMLFactory<HTMLElement>
  const del: HTMLFactory<HTMLElement>
  const details: HTMLFactory<HTMLElement>
  const dfn: HTMLFactory<HTMLElement>
  const dialog: HTMLFactory<HTMLDialogElement>
  const div: HTMLFactory<HTMLDivElement>
  const dl: HTMLFactory<HTMLDListElement>
  const dt: HTMLFactory<HTMLElement>
  const em: HTMLFactory<HTMLElement>
  const embed: HTMLFactory<HTMLEmbedElement>
  const fieldset: HTMLFactory<HTMLFieldSetElement>
  const figcaption: HTMLFactory<HTMLElement>
  const figure: HTMLFactory<HTMLElement>
  const footer: HTMLFactory<HTMLElement>
  const form: HTMLFactory<HTMLFormElement>
  const h1: HTMLFactory<HTMLHeadingElement>
  const h2: HTMLFactory<HTMLHeadingElement>
  const h3: HTMLFactory<HTMLHeadingElement>
  const h4: HTMLFactory<HTMLHeadingElement>
  const h5: HTMLFactory<HTMLHeadingElement>
  const h6: HTMLFactory<HTMLHeadingElement>
  const head: HTMLFactory<HTMLHeadElement>
  const header: HTMLFactory<HTMLElement>
  const hgroup: HTMLFactory<HTMLElement>
  const hr: HTMLFactory<HTMLHRElement>
  const html: HTMLFactory<HTMLHtmlElement>
  const i: HTMLFactory<HTMLElement>
  const iframe: HTMLFactory<HTMLIFrameElement>
  const img: HTMLFactory<HTMLImageElement>
  const input: HTMLFactory<HTMLInputElement>
  const ins: HTMLFactory<HTMLModElement>
  const kbd: HTMLFactory<HTMLElement>
  const keygen: HTMLFactory<HTMLElement>
  const label: HTMLFactory<HTMLLabelElement>
  const legend: HTMLFactory<HTMLLegendElement>
  const li: HTMLFactory<HTMLLIElement>
  const link: HTMLFactory<HTMLLinkElement>
  const main: HTMLFactory<HTMLElement>
  const map: HTMLFactory<HTMLMapElement>
  const mark: HTMLFactory<HTMLElement>
  const menu: HTMLFactory<HTMLElement>
  const menuitem: HTMLFactory<HTMLElement>
  const meta: HTMLFactory<HTMLMetaElement>
  const meter: HTMLFactory<HTMLElement>
  const nav: HTMLFactory<HTMLElement>
  const noindex: HTMLFactory<HTMLElement>
  const noscript: HTMLFactory<HTMLElement>
  const object: HTMLFactory<HTMLObjectElement>
  const ol: HTMLFactory<HTMLOListElement>
  const optgroup: HTMLFactory<HTMLOptGroupElement>
  const option: HTMLFactory<HTMLOptionElement>
  const output: HTMLFactory<HTMLElement>
  const p: HTMLFactory<HTMLParagraphElement>
  const param: HTMLFactory<HTMLParamElement>
  const picture: HTMLFactory<HTMLElement>
  const pre: HTMLFactory<HTMLPreElement>
  const progress: HTMLFactory<HTMLProgressElement>
  const q: HTMLFactory<HTMLQuoteElement>
  const rp: HTMLFactory<HTMLElement>
  const rt: HTMLFactory<HTMLElement>
  const ruby: HTMLFactory<HTMLElement>
  const s: HTMLFactory<HTMLElement>
  const samp: HTMLFactory<HTMLElement>
  const slot: HTMLFactory<HTMLSlotElement>
  const script: HTMLFactory<HTMLScriptElement>
  const section: HTMLFactory<HTMLElement>
  const select: HTMLFactory<HTMLSelectElement>
  const small: HTMLFactory<HTMLElement>
  const source: HTMLFactory<HTMLSourceElement>
  const span: HTMLFactory<HTMLSpanElement>
  const strong: HTMLFactory<HTMLElement>
  const style: HTMLFactory<HTMLStyleElement>
  const sub: HTMLFactory<HTMLElement>
  const summary: HTMLFactory<HTMLElement>
  const sup: HTMLFactory<HTMLElement>
  const table: HTMLFactory<HTMLTableElement>
  const template: HTMLFactory<HTMLTemplateElement>
  const tbody: HTMLFactory<HTMLTableSectionElement>
  const td: HTMLFactory<HTMLTableDataCellElement>
  const textarea: HTMLFactory<HTMLTextAreaElement>
  const tfoot: HTMLFactory<HTMLTableSectionElement>
  const th: HTMLFactory<HTMLTableHeaderCellElement>
  const thead: HTMLFactory<HTMLTableSectionElement>
  const time: HTMLFactory<HTMLElement>
  const title: HTMLFactory<HTMLTitleElement>
  const tr: HTMLFactory<HTMLTableRowElement>
  const track: HTMLFactory<HTMLTrackElement>
  const u: HTMLFactory<HTMLElement>
  const ul: HTMLFactory<HTMLUListElement>
  // const 'var': HTMLFactory<HTMLElement>
  const video: HTMLFactory<HTMLVideoElement>
  const wbr: HTMLFactory<HTMLElement>
  const webview: HTMLFactory<HTMLWebViewElement>
}
// export const div = createFactory('div')
// export const img = createFactory('img')
