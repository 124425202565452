import { jsx, Global, css } from '@emotion/core'
import { graphql } from 'gatsby'
// import { AssetsYamlIndex } from 'types/graphql'
import { IndexQueryQuery } from '../../types/graphql-types.d'
import HeroArea from '../components/HeroArea'
import HeaderNav from '../components/HeaderNav'
import ArticleHeading from '../components/ArticleHeading'
import SubSection from '../components/SubSection'
import smoothScroll from '../utils/smoothScroll'

const spansNobr: (...strings: string[]) => React.ReactNode = (
  ...strings
) => p(
  { css: {
    display: 'flex',
    flexWrap: 'wrap',
    // justifyContent: 'center',
  } },
  ...strings.map(
    (str) => span({ key: str, css: { whiteSpace: 'nowrap' } }, str || ''),
  ),
)

const listGen: (...strings: string[]) => React.ReactNode = (
  ...strings
) => ul({},
  ...strings.map(
    (str) => li({ key: str }, span({}, str)),
  ),
)

const styleInlineList = css({
  '>ul': {
    listStyle: 'none',
    padding: 0,
    display: 'flex',
    flexWrap: 'wrap',
    '>li': {
      margin: '.25em 0em',
      padding: '0 1em',
      width: '100%',
      '::before': {
        content: '""',
        display: 'inline-block',
        width: '.5em', height: '.5em',
        border: '1px solid #999',
        marginRight: '.4em',
        transform: 'translateY(-15%)',
      },
    },
  },
})

const styleTable = css({
  margin: '0 auto 1rem',
  '>tbody>tr>th': {
    width: '6em',
    textAlign: 'center',
    padding: '.25rem .5rem',
    borderBottom: '1px solid #AAA',
  },
  '>tbody>tr>td': {
    padding: '.25rem .5rem',
    borderBottom: '1px solid #AAA',
  },
  '>tbody>tr:last-child': {
    'th, td': { borderBottom: 'none' },
  },
})

const App: FH<{ data: IndexQueryQuery }> = ({ data: { allAssetsYaml: { edges: [{ node: { index: data } }] } } }) => {
  // console.log(data)
  return div(
    { id: 'top', css: {
      backgroundColor: '#EBF6FA',
      // backgroundImage: 'url("https://yokoiwa-s3.imgix.net/Sunyou/polygonal-pattern.svg")',
      backgroundImage: 'url("https://yokoiwa-s3.imgix.net/Sunyou/polygonal-pattern.svg"), linear-gradient(#EBF6FA,#ebfaf1,#faf0eb,#efebfa)',
      backgroundSize: '150px',
    } },
    jsx(Global, {
      styles: css([{
        fontFamily: '"Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif',
        // ':root.wf-active body': {
        //   fontFamily: 'fot-tsukuardgothic-std, sans-serif',
        // },
        'h1,h2,h3,h4,h5': {
          lineHeight: 1,
          fontSize: 'inherit',
          marginBottom: 0,
        },
        'img': {
          maxWidth: '100%',
        },
      }]),
    }),
    div(
      {
        css: {
          marginTop: '-2rem',
          '@media(min-width: 100vh)': { marginBottom: '-2rem' },
        },
      },
      HeroArea({ data: data?.body?.HeroHeader }),
    ),
    div(
      { css: {
        paddingBottom: '3rem',
        '@media(min-width: 100vh)': { paddingBottom: 0 },
      } },
      HeaderNav({}),
      article( // section area
        { id: 'trust' },
        ArticleHeading({ title: 'お客様の安心と信頼のために' },
          spansNobr('1973年の創業以来、','私たち株式会社三裕は、 ','その時代ごとに、','最も適した','資源リサイクルを','してまいりました。'),
          spansNobr('これからも','法令順守のもと、','お客様の','安心と信頼を第一に、 ','お預りした資源を','責任をもって','リサイクルいたします。'),
          // spansNobr('1973年の創業以来、', 'わたしたち株式会社三裕 (さんゆう) は、', '法令遵守をモットーに', '経営を続けてまいりました。'),
          // spansNobr('世間はリサイクル資源の', '不適切処理・不法投棄が', 'たびたび問題になっています。'),
          // spansNobr('三裕には、', '長年の営業のなかで培った', '数々の信頼と実績があります。', 'お客様の安心を第一に、', 'お預りした資源を', '責任をもってリサイクルいたします。'),
        ),
        SubSection(
          { title: '株式会社三裕とは' },
          p({}, '昭和40年代、まだリサイクルという概念が多くの人々に認識されていなかった頃、市場にあふれるワンウェイびんをいかに再利用できるかを考え、回収システムを確立させてきました。'),
          p({}, '時代とともにエネルギーの削減・資源の再利用と世界レベルでリサイクルの重要性が叫ばれるなか、企業との太い絆を構築し、多くの資源回収の取り組みを推進してまいりました。'),
          p({}, 'またお客様の需要に応え、近年では一部の再利用が難しい色付きびんのグラスウール原料化や、びんのみに留まらず硬質プラスチックの再生原料化などの新たな再資源化の仕組みを導入しています。'),
          p({}, '貴重な資源を無駄にしないため。安全で安心できる生活を守るため。資源のリサイクルシステムが次世代の環境に大きく貢献できることを確信し、さらなる開発と改革を推し進めてまいります。'),
        ),
        SubSection(
          { title: '主要取引先' },
          div(
            {css: [styleInlineList, { '>ul >li': { '@media(min-width:520px)': { width: '50%' } } }] },
            p({ css: { textAlign: 'right', fontSize: '.8em', margin: '-.75rem 0 .5rem' } }, '※ 順不同、一部'),
            listGen('サントリー株式会社', 'キリンビール株式会社', 'アサヒビール株式会社', 'サッポロビール株式会社', '宝酒造株式会社', '三和酒類株式会社', '大塚製薬株式会社', '硝和ガラス株式会社', 'ガラスリソーシング株式会社', '株式会社光陽', '株式会社カクヤス', '株式会社小倉', '田野屋酒販株式会社', '株式会社饒田' , '国分株式会社', 'フィット株式会社', '株式会社柴田屋酒店', '他 首都圏の業務用酒販店'),
          ),
        ),
        SubSection(
          { title: '加盟団体' },
          div(
            {css: [styleInlineList, { '>ul >li': { '@media(min-width:580px)': { width: '50%' } } }] },
            listGen('東京廃棄物事業協同組合','東京都産業資源循環協会','埼玉県環境産業振興協会','埼玉県再生資源事業協同組合','サントリー容器直納店会','日本びんカレットリサイクル協会','ガラスびん3R促進協議会','全国びん商連合会','東京壜容器協同組合'),
          ),
        ),
        SubSection(
          { title: '許認可・認定' },
          div(
            { css: styleInlineList },
            ul({},
              li({}, span({}, '産業廃棄物収集運搬業認可',br({}),'　(東京都・埼玉県・千葉県・神奈川県・茨城県・栃木県・群馬県)')),
              li({}, span({}, '廃棄物再生事業者登録 (埼玉県)')),
              li({}, span({}, '再生資源回収事業者認定 (日本再生資源事業協同組合連合会)')),
              li({}, span({}, '産廃プロフェッショナル認定 (東京都環境公社)')),
            ),
          ),
        ),
      ),
      article( // section area
        { id: 'recycling' },
        ArticleHeading({ title: '三裕のリサイクルシステム' },
        ),
        SubSection(
          { title: 'ガラスびんのリユース・リサイクル' },
          p({}, 'ガラスびんは、洗浄して再使用するリターナブルびん (一升びんやビールびん等) と、カレットにして製びん原料やグラスウール原料となるワンウェイびんとの２種類に分類されます。現状ではカレットにされるワンウェイびんが大半を占めています。'),
          figure({},
            img({ src: 'https://yokoiwa-s3.imgix.net/Sunyou/glassbottle-recycling-system.png?w=1200&q=.1&fm=jpg', alt: '' }),
            figcaption({}, 'ワンウェイびんとリターナブルびん、そのリサイクル方法'),
          ),
          p({}, 'ガラスびんを中心に、板ガラス・耐熱ガラス・硬質ガラスなど、建物の解体や研究施設等で発生するものまで、リサイクルをしています。'),
          p({}, 'お客様のお手元からの回収、種類に応じた適切な再資源化処理、そして廃棄物全般におけるリサイクリングシステムのコンサルティングまで、ワンストップで解決します。'),
        ),
        SubSection(
          { title: 'ワンウェイびんの分別' },
          p({}, '市場に流通しているガラスびんの大半を占めるワンウェイびんですが、色分別をして再資源化を行っています。'),
          // p({}, '無色透明のびんや色の規格が決まっている茶色のびんは、それぞれ色別に仕分けて粉砕してカレットと呼ばれる細かな粒状にし、再び新しい透明・茶色びんの原料になります。'),
          figure({},
            img({ src: 'https://yokoiwa-s3.imgix.net/Sunyou/cullet-colors-01.png?w=1200&q=.1&fm=jpg', alt: '' }),
            figcaption({}, 'さまざまな色のびんのカレット'),
          ),
          p({}, 'しかし、緑や黒などの色付きびんは、製瓶原料としての需要よりも発生量が多くなっています。'),
          p({}, '三裕ではこれらをグラスウール原料に再生しています。グラスウールは建築用資材の断熱材などに使用されています。'),
          figure({},
            img({ src: 'https://yokoiwa-s3.imgix.net/Sunyou/glasswool.jpg?w=1200&q=.1&fm=jpg', alt: '' }),
            figcaption({}, '断熱材・吸音材となるグラスウール'),
          ),
        ),
        SubSection(
          { title: '硬質プラスチックのリサイクル' },
          p({}, 'プラスチック製コンテナ類もまたリサイクルの対象です。'),
          p({}, '基本的にこれらは、そのまま再び使われるのですが、中には劣化や破損によって再使用が難しいものもあります。'),
          p({}, 'これらを洗浄粉砕してプラスチックチップにし、新たにパレットなどの素材として使えるようにリサイクルを行っています。'),
          figure({},
            img({ src: 'https://yokoiwa-s3.imgix.net/Sunyou/plastic-pallete.JPG?w=1200&q=.1&fm=jpg', alt: '' }),
            figcaption({}, '硬質プラスチックのパレット'),
          ),
        ),
      ),
      article( // section area
        { id: 'facilities' },
        ArticleHeading({ title: '施設・ライン設備について' },
        ),
        SubSection(
          { title: '草加センター' },
          p({ css: { textAlign: 'right', fontSize: '.8em', margin: '-.75rem 0 .25rem' } }, '埼玉県草加市青柳 八丁目1526-1'),
          p({}, 'ワンウェイびんの色選別、及び産業廃棄物の積替保管を行っています。'),
          figure({},
            img({ src: 'https://yokoiwa-s3.imgix.net/Sunyou/02RIMG0185.jpg?w=1200&q=.1&fm=jpg', alt: '' }),
            figcaption({}, '草加センター外観'),
          ),
          figure({},
            img({ src: 'https://yokoiwa-s3.imgix.net/Sunyou/souka_center-01.JPG?w=1200&q=.1&fm=jpg', alt: '' }),
            figcaption({}, '色分別したガラスびんの保管エリア'),
          ),
          figure({},
            img({ src: 'https://yokoiwa-s3.imgix.net/Sunyou/souka_center-02.JPG?w=1200&q=.1&fm=jpg', alt: '' }),
            figcaption({}, 'ガラスびんの色分別を行う様子'),
          ),
        ),
        SubSection(
          { title: '越谷センター' },
          p({ css: { textAlign: 'right', fontSize: '.8em', margin: '-.75rem 0 .25rem' } }, '埼玉県越谷市川柳町 4-321'),
          p({}, 'リターナブルびんの選別を行っています。'),
          figure({},
            img({ src: 'https://yokoiwa-s3.imgix.net/Sunyou/DSCF1096.JPG?w=1200&q=.1&fm=jpg', alt: '' }),
            figcaption({}, '大量のリターナブルびんが保管されている'),
          ),
        ),
        SubSection(
          { title: '八潮センター' },
          p({ css: { textAlign: 'right', fontSize: '.8em', margin: '-.75rem 0 .25rem' } }, '埼玉県八潮市二丁目 1121-1'),
          p({}, 'グラスウール原料製造・プラスチックコンテナ類の洗浄粉砕を行っています。'),
          figure({},
            img({ src: 'https://yokoiwa-s3.imgix.net/Sunyou/0001.jpg?w=1200&q=.1', alt: '' }),
            figcaption({}, '八潮センター外観'),
          ),
          figure({},
            img({ src: 'https://yokoiwa-s3.imgix.net/Sunyou/IMG_8537.JPG?w=1200&q=.1', alt: '' }),
            figcaption({}, '八潮センター内観'),
          ),
          figure({},
            img({ src: 'https://yokoiwa-s3.imgix.net/Sunyou/yashio-glasswool-progress01.JPG?w=1200&q=.1', alt: '' }),
            figcaption({}, '緑色ガラスびんのカレットからラベルなどの不純物を取りのぞく'),
          ),
          figure({},
            img({ src: 'https://yokoiwa-s3.imgix.net/Sunyou/AI_Image.jpg?w=1200&q=.1', alt: '' }),
            figcaption({}, 'プラスチック洗浄粉砕ライン'),
          ),
        ),
      ),
      article( // section area
        { id: 'company' },
        ArticleHeading({ title: '会社情報' },
        ),
        SubSection(
          { title: '会社概要' },
          table({ css: styleTable },
            tbody({},
              tr({}, th({}, '商号'), td({}, '株式会社 三裕')),
              tr({}, th({}, '代表取締役'), td({}, '村瀬 二重')),
              tr({}, th({}, '創業'), td({}, '昭和48年3月15日')),
              tr({}, th({}, '法人設立'), td({}, '昭和49年3月15日')),
              tr({}, th({}, '資本金'), td({}, '5,100万円')),
              tr({}, th({}, '住所'), td({},
                '〒343-0827', br(),
                '埼玉県越谷市川柳町 4-325-4', br(),
                'TEL.048-990-1700 FAX.048-990-1707',
              )),
            ),
          ),
        ),
        SubSection(
          { title: '沿革' },
          table({ css: styleTable },
            tbody({},
              tr({}, th({}, '昭和48年 3月'), td({},
                'ワンウェイびんの回収。ガラスカレットの再利用、用途開発、加工を目的として個人にて創業。', br(),
                '東京都内一部酒販店より回収開始。',
              )),
              tr({}, th({}, '昭和49年 3月'), td({},
                '法人組織変更、資本金3百万円',
              )),
              tr({}, th({}, '昭和50年 5月'), td({},
                'ワンウェイびん問題の拡大に伴い、関東地区 (東京都内・都下・神奈川・埼玉・千葉・栃木・茨城・群馬) 回収開始。',
              )),
              tr({}, th({}, '昭和53年 4月'), td({},
                '資本金3百万円より6百万円に増資',
              )),
              tr({}, th({}, '昭和53年 7月'), td({},
                '資本金6百万円より1千万円に増資',
              )),
              tr({}, th({}, '平成9年 10月'), td({},
                '資本金1千万円より4千5百万円に増資',
              )),
              tr({}, th({}, '平成17年 4月'), td({},
                '資本金4千5百万円より5千百万円に増資',
              )),
              tr({}, th({}, '平成18年 6月'), td({},
                '東京都荒川区西日暮里より東京都江東区南砂へ本社移転',
              )),
              tr({}, th({}, '平成20年 7月'), td({},
                '東京都江東区南砂より本社移転。', br(), '埼玉県八潮市古新田よりやしおサテライト移転。',
              )),
              tr({}, th({}, '平成28年 8月'), td({},
                '埼玉県八潮市に八潮センター開設',
              )),
              // tr({}, th({}, '令和2年'), td({},
              //   '八潮センターにて産業廃棄物処分業許可取得予定 (6月頃)',
              // )),
            ),
          ),
        ),
        SubSection(
          { title: '営業内容' },
          p({}, '回収及び受入品目'),
          listGen('ワンウェイびん', 'リターナブルびん', 'プラスチック製コンテナ類', '産業廃棄物（ガラスくず、陶磁器くず、廃プラスチック類、蛍光灯、乾電池）'),
          // p({}, '当社は、主に首都圏の業務用酒販店から発生する空きびんの回収及び再生を業としています。専用の回収容器 (Ｐ箱) を設置し、定期回収及び色分別による再生を行っております。'),
          // p({}, 'また最近では、ガラスくず・陶磁器くず、廃プラスチック類 (P箱等の硬質系)、蛍光灯・乾電池などの産業廃棄物の受入れも行ってまります。'),
        ),
        div(
          { css: { display: 'flex', justifyContent: 'center' } },
          a(
            {
              href: 'http://www2.sanpainet.or.jp/zyohou/index_u2.php?UserID=778487', target: '_blank',
              css: { display: 'block', border: '8px solid #FFF', borderRadius: '8px' },
            },
            img({ src: 'https://yokoiwa-s3.imgix.net/Sunyou/emblem.gif.png', alt: '産廃情報ネット' }),
          ),
        ),
      ),
      div(
        { css: {
          marginTop: '6rem',
          paddingBottom: '2rem',
          backgroundColor: '#30387A',
          backgroundImage: 'url("https://yokoiwa-s3.imgix.net/Sunyou/polygonal-pattern.svg")',
          backgroundSize: '250px',
          color: '#FFF',
          textAlign: 'center',
        } },
        ul(
          { css: {
            maxWidth: '50rem',
            margin: '0 auto',
            padding: '2rem 0',
            listStyle: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            '>li': {
              margin: '.5rem 1rem',
              '>a': {
                color: 'inherit',
                textDecoration: 'none',
              },
            },
          } },
          li({}, a({ onClick: smoothScroll, href: '#top' }, 'トップ')),
          li({}, a({ onClick: smoothScroll, href: '#trust' }, 'お客様の安心と信頼のために')),
          li({}, a({ onClick: smoothScroll, href: '#recycling' }, '三裕のリサイクルシステム')),
          li({}, a({ onClick: smoothScroll, href: '#facilities' }, '施設・ライン設備について')),
          li({}, a({ onClick: smoothScroll, href: '#company' }, '会社情報')),
        ),
        p({}, '© 三裕株式会社'),
      ),
    ),
  )
}

export const query = graphql`
query indexQuery {
  allAssetsYaml {
    edges {
      node {
        index {
          body {
            HeroHeader {
              copy
            }
          }
        }
      }
    }
  }
}
`

export default App
