import HeroAreaBackground from './HeroAreaBackground'
import HeroAreaCopyLine from './HeroAreaCopyLine'
import HeroAreaCopyLogo from './HeroAreaCopyLogo'

const HeroArea: FH = () => {
  return div(
    {
      css: {
        position: 'relative',
        width: '100%', height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center', alignItems: 'center',
      },
    },
    HeroAreaBackground({}),
    HeroAreaCopyLine({}),
    HeroAreaCopyLogo({}),
  )
}

export default HeroArea
