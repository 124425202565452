const HeroAreaCopyLine: FH = () => div(
  {
    css: {
      // outer
      position: 'absolute',
      transform: 'translate(0, calc(-50% + 10px))',
      '@media(min-width: 100vh)': {
        transform: 'translate(calc(-50% + 10px), 0)',
      },
      // self
      backgroundImage: 'url(https://yokoiwa-s3.imgix.net/Sunyou/polygonal-pattern.svg)',
      backgroundSize: '250px',
      backgroundColor: '#426CA2',
      boxShadow: '0 0 2rem -1rem #FFF',
      // inner
      padding: '10px',
      overflow: 'hidden',
    },
  },
  div(
    {
      css: {
        // outer
        // position: 'absolute',
        width: '25vh', height: '25vh',
        '@media(min-width: 50vh)': {
          width: '50vw', height: '50vw',
        },
        '@media(min-width: 66.666666vh)': {
          width: '33.333333vh', height: '33.333333vh',
        },
        '@media(min-width: 100vh)': {
          width: '33.333333vw', height: '33.333333vw',
        },
        '@media(min-width: 150vh)': {
          width: '50vh', height: '50vh',
        },
        '@media(min-width: 200vh)': {
          width: '25vw', height: '25vw',
        },
        // width: '23rem', height: '23rem',
        // self
        border: '5px solid #FFF',
        // inner
        display: 'flex',
        justifyContent: 'center', alignItems: 'center',
      },
    },
    img({ src: 'https://yokoiwa-s3.imgix.net/Sunyou/reborn-logo.svg', alt: 'Glass Recycle, Reborn', css: { width: '90%', height: '90%' } }),
  ),
)


export default HeroAreaCopyLine
